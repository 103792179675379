import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "custom-input-number__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_FormItem = _resolveComponent("FormItem")!

  return (_openBlock(), _createBlock(_component_FormItem, {
    label: _ctx.$attrs.label,
    name: _ctx.$attrs.name,
    value: _ctx.$attrs.value,
    rules: _ctx.$attrs.rules,
    extra: _ctx.$attrs.extra,
    help: _ctx.$attrs.help,
    validateFirst: _ctx.validateFirst,
    validateTrigger: _ctx.$attrs.validateTrigger
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "custom-input-number__custom-stepper stepper-left",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stepDownHandler && _ctx.stepDownHandler(...args)))
        }, _toDisplayString("-")),
        _createVNode(_component_a_input_number, _mergeProps({ autocomplete: "disabled" }, _ctx.$attrs, {
          "on-focus": _ctx.focusHandler,
          ref: "inputNumberRef",
          min: _ctx.min,
          max: _ctx.max,
          controls: false,
          value: _ctx.valueModel,
          class: "custom-input-number"
        }), {
          prefix: _withCtx(() => [
            _renderSlot(_ctx.$slots, "prefix")
          ]),
          suffix: _withCtx(() => [
            _renderSlot(_ctx.$slots, "suffix")
          ]),
          _: 3
        }, 16, ["on-focus", "min", "max", "value"]),
        _createElementVNode("div", {
          class: "custom-input-number__custom-stepper stepper-right",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.stepUpHandler && _ctx.stepUpHandler(...args)))
        }, _toDisplayString("+"))
      ])
    ]),
    _: 3
  }, 8, ["label", "name", "value", "rules", "extra", "help", "validateFirst", "validateTrigger"]))
}