import {
  SubmitPublicCreatorRequest,
  SubmitPublicCreatorRequestVariables,
  SubmitPublicCreatorRequest_submitPublicCreatorRequest_GenericSuccess,
} from "@/api/leads/__generated__/SubmitPublicCreatorRequest";
import { SubmitPublicCreatorRequestInput } from "@/../__generated__/globalTypes";
import { submitPublicCreatorRequestGql } from "@/api/leads/submitPublicCreatorRequest";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import isEmpty from "lodash/isEmpty";

export const useLeads = () => {
  const {
    mutate: submitPublicCreatorRequestMutate,
    loading: submitPublicCreatorRequestLoading,
  } = useCustomMutation<
    SubmitPublicCreatorRequest,
    SubmitPublicCreatorRequestVariables
  >(submitPublicCreatorRequestGql);

  // === Handlers ===
  const submitPublicCreatorRequest = async (
    input: SubmitPublicCreatorRequestInput
  ) => {
    const response = await submitPublicCreatorRequestMutate({ input });

    const parsedResponse =
      parseGqlResponse<SubmitPublicCreatorRequest_submitPublicCreatorRequest_GenericSuccess>(
        "GenericSuccess",
        response
      );

    if (!isEmpty(parsedResponse.error?.errors) || !response) {
      throw new Error("Failed to submit request");
    }

    return parsedResponse.data;
  };

  return {
    submitPublicCreatorRequest,
    submitPublicCreatorRequestLoading,
  };
};
