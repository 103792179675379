import { performerDetailsGql } from "@/api/performer/performerDetails";
import { useQuery } from "@vue/apollo-composable";
import { computed } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import {
  PerformerDetails,
  PerformerDetailsVariables,
  PerformerDetails_performerDetails_PerformerDetails,
} from "@/api/performer/__generated__/PerformerDetails";
import { HideIfErrorCode } from "@/shared/utils/graphql/errorHandler";

export type PerformerDetailsQueryOptions = {
  hideError?: boolean;
};

export const usePerformerDetails = (
  input: PerformerDetailsVariables,
  options?: PerformerDetailsQueryOptions
) => {
  const { result: performerDetailsResult, loading: performerDetailsLoading } =
    useQuery<PerformerDetails, PerformerDetailsVariables>(
      performerDetailsGql,
      input
    );

  const performerDetails = computed(() => {
    const parsedResponse =
      parseGqlResponse<PerformerDetails_performerDetails_PerformerDetails>(
        "PerformerDetails",
        performerDetailsResult.value,
        options?.hideError ? HideIfErrorCode.ALL_ERRORS : null
      );

    return parsedResponse?.data;
  });

  return {
    performerDetails,
    performerDetailsLoading,
  };
};
