import gql from "graphql-tag";

export const performerDetailsGql = gql`
  query PerformerDetails($input: IdInput!) {
    performerDetails(input: $input) {
      ... on PerformerDetails {
        id
        name
        performerProfile {
          metadata
          nickname
          introduction
          feedback
          age
          industries {
            id
            created
            modified
            name
          }
          gender
          isLocked
          avatar
          videoUrls
          location
          deliveryTime
          rating
          videoCount
          images {
            image
            description
            altText
            slug
            thumbnail
          }
          badges {
            id
            name
          }
          isPremium
          publicRate
        }
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
