import gql from "graphql-tag";

export const submitPublicCreatorRequestGql = gql`
  mutation SubmitPublicCreatorRequest(
    $input: SubmitPublicCreatorRequestInput!
  ) {
    submitPublicCreatorRequest(input: $input) {
      ... on GenericSuccess {
        message
        success
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
