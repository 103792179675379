
import { defineComponent, ref, watch } from "vue";
import FormItem from "@/shared/components/Forms/FormItem.vue";
import { useVModel } from "vue-composable";

export default defineComponent({
  inheritAttrs: false,
  props: {
    isFocusedModel: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    validateFirst: {
      type: Boolean,
      default: true,
    },
    /** FIXME: Enhance by converting into number and by default
     * us Infinity up and down. To reduce code. */
    min: String,
    max: String,
    valueModel: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:isFocusedModel", "get-input-number-ref"],
  setup(props, { emit }) {
    const isFocused = useVModel(props, "isFocusedModel");
    const valueModel = useVModel(props, "valueModel");

    // We need this internally in case no props is passed for [isFocusedModel]
    // [useVModel] does not automatically create a reactive variable if there is no props
    const isFocusedInternal = ref();

    const focusHandler = () => {
      isFocused.value = true;
      isFocusedInternal.value = true;
    };

    const inputNumberRef = ref();
    if (props.autofocus) {
      setTimeout(() => inputNumberRef.value?.focus(), 500);
    }

    /**
     * Used to exposed internal input ref directly
     */
    watch(inputNumberRef, (ref) => {
      emit("get-input-number-ref", ref);
    });

    const stepDownHandler = () => {
      if (props.min) {
        const min = parseInt(props.min);
        if (valueModel.value > min) {
          valueModel.value--;
        }
      } else {
        valueModel.value--;
      }
    };

    const stepUpHandler = () => {
      if (props.max) {
        const max = parseInt(props.max);
        if (valueModel.value < max) {
          valueModel.value++;
        }
      } else {
        valueModel.value++;
      }
    };

    return {
      focusHandler,
      inputNumberRef,
      isFocused,
      isFocusedInternal,
      stepDownHandler,
      stepUpHandler,
    };
  },
  components: {
    FormItem,
  },
});
