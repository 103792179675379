import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "performer-work-with-me"
}
const _hoisted_2 = { class: "performer-work-with-me__wrapper" }
const _hoisted_3 = { class: "performer-work-with-me__column column-left" }
const _hoisted_4 = { class: "performer-work-with-me__column__background column-left" }
const _hoisted_5 = { class: "performer-work-with-me__column__container column-left" }
const _hoisted_6 = { class: "performer-work-with-me__performer" }
const _hoisted_7 = { class: "performer-work-with-me__performer__card" }
const _hoisted_8 = { class: "performer-work-with-me__column column-right" }
const _hoisted_9 = { class: "performer-work-with-me__column__background column-right" }
const _hoisted_10 = {
  key: 0,
  class: "performer-work-with-me__form"
}
const _hoisted_11 = { class: "performer-work-with-me__form__form-field-group" }
const _hoisted_12 = { class: "block mb-1" }
const _hoisted_13 = { class: "performer-work-with-me__form__form-field-group" }
const _hoisted_14 = { class: "block mb-1" }
const _hoisted_15 = { class: "performer-work-with-me__form__form-field-group" }
const _hoisted_16 = { class: "block mb-1" }
const _hoisted_17 = { class: "performer-work-with-me__form__form-field-group" }
const _hoisted_18 = { class: "block mb-1" }
const _hoisted_19 = { class: "performer-work-with-me__form__form-field-group" }
const _hoisted_20 = { class: "block mb-1" }
const _hoisted_21 = { class: "performer-work-with-me__form__form-field-group" }
const _hoisted_22 = { class: "block mb-1" }
const _hoisted_23 = { class: "performer-work-with-me__form__form-field-group" }
const _hoisted_24 = { class: "block mb-1" }
const _hoisted_25 = {
  key: 1,
  class: "performer-work-with-me__form-success",
  id: "performer-work-with-me__form-success"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_PerformerCard = _resolveComponent("PerformerCard")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputPhoneNumber = _resolveComponent("InputPhoneNumber")!
  const _component_InputTextArea = _resolveComponent("InputTextArea")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputCheckbox = _resolveComponent("InputCheckbox")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_ctx.performerDetailsLoading)
    ? (_openBlock(), _createBlock(_component_a_skeleton, {
        key: 0,
        active: ""
      }))
    : (!!_ctx.performerDetails)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_LayoutIcon, {
                  name: "brushPink2",
                  id: "brushPink2"
                }),
                _createVNode(_component_LayoutIcon, {
                  name: "brushPink3",
                  id: "brushPink3"
                }),
                _createVNode(_component_LayoutIcon, {
                  name: "ellipse2",
                  id: "ellipse2"
                }),
                _createVNode(_component_LayoutIcon, {
                  name: "dots",
                  id: "dots"
                }),
                _createVNode(_component_LayoutIcon, {
                  name: "twinkle4",
                  id: "twinkle4"
                })
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_a_typography_title, {
                    class: "mb-8",
                    level: 2
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Welcome!")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_typography_paragraph, { class: "performer-work-with-me__performer__message" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t(
                  "Are you a business owner who needs video content for your ads? Or maybe you want to be on Tiktok/FB/IG but you have no time to create videos or it is too expensive to go to agencies?"
                )), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_typography_paragraph, { class: "performer-work-with-me__performer__message" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t(
                  "Then you are in the right place. You can hire our UGC creators to create video contents for you. Video starts at only P1,000 pesos per video depending on your need. Drop your info here so we can reach out for a discovery call."
                )), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_PerformerCard, {
                      performer: _ctx.performerDetails,
                      "mini-card": ""
                    }, null, 8, ["performer"])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_LayoutIcon, {
                  name: "brushBlue2",
                  id: "brushBlue2"
                }),
                _createVNode(_component_LayoutIcon, {
                  name: "brushBlue3",
                  id: "brushBlue3"
                }),
                _createVNode(_component_LayoutIcon, {
                  name: "ellipse3",
                  id: "ellipse3"
                }),
                _createVNode(_component_LayoutIcon, {
                  name: "dots2",
                  id: "dots2"
                }),
                _createVNode(_component_LayoutIcon, {
                  name: "twinkle5",
                  id: "twinkle5"
                })
              ]),
              _createElementVNode("div", {
                class: _normalizeClass([
            'performer-work-with-me__column__container',
            'column-right',
            _ctx.formSuccess && 'form-success',
          ])
              }, [
                (!_ctx.formSuccess)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_a_typography_title, {
                        class: "mb-10",
                        level: 2
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("UGC Creator Booking!")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_FormGroup, {
                        model: _ctx.formState,
                        onFinish: _ctx.handleValidForm
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("label", _hoisted_12, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t("Business Name *")), 1)
                            ]),
                            _createVNode(_component_InputText, {
                              value: _ctx.formState.businessName,
                              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.businessName) = $event)),
                              name: "businessName",
                              placeholder: _ctx.t('The name of your brand'),
                              rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                            }, null, 8, ["value", "placeholder", "rules"])
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("label", _hoisted_14, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t("Email Address *")), 1)
                            ]),
                            _createVNode(_component_InputText, {
                              value: _ctx.formState.email,
                              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.email) = $event)),
                              name: "email",
                              placeholder: _ctx.t('username@domain.com'),
                              rules: [
                    _ctx.makeEmailRule(_ctx.t('Please enter a valid email address.')),
                    _ctx.makeRequiredRule(_ctx.t('This field is required.')),
                  ]
                            }, null, 8, ["value", "placeholder", "rules"])
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("label", _hoisted_16, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t("Phone Number")), 1)
                            ]),
                            _createVNode(_component_InputPhoneNumber, {
                              value: _ctx.formState.phoneNumber,
                              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.phoneNumber) = $event)),
                              name: "phoneNumber",
                              placeholder: _ctx.t('+63xxxxxxxxxx')
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("label", _hoisted_18, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t("Instagram Link")), 1)
                            ]),
                            _createVNode(_component_InputText, {
                              value: _ctx.formState.instagramUrl,
                              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.instagramUrl) = $event)),
                              name: "instagramUrl",
                              placeholder: _ctx.t('https://')
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("label", _hoisted_20, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t("What kind of videos do you need?")), 1)
                            ]),
                            _createVNode(_component_InputTextArea, {
                              value: _ctx.formState.description,
                              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.description) = $event)),
                              name: "description",
                              placeholder: _ctx.t('The kind of videos I need...'),
                              rows: 3
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("label", _hoisted_22, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t("How many videos do you need?")), 1)
                            ]),
                            _createVNode(_component_InputNumber, {
                              valueModel: _ctx.formState.videoCount,
                              "onUpdate:valueModel": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.videoCount) = $event)),
                              name: "videoCount",
                              min: "1"
                            }, null, 8, ["valueModel"])
                          ]),
                          _createElementVNode("div", _hoisted_23, [
                            _createElementVNode("label", _hoisted_24, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t("Where did you find us?")), 1)
                            ]),
                            _createVNode(_component_InputCheckbox, {
                              name: "discovery",
                              value: _ctx.formState.discovery,
                              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.discovery) = $event)),
                              multiple: true,
                              options: _ctx.discoveryOptions,
                              vertical: "",
                              class: "mb-1"
                            }, null, 8, ["value", "options"]),
                            _createVNode(_component_InputText, {
                              value: _ctx.formState.discoveryOther,
                              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formState.discoveryOther) = $event)),
                              name: "discoveryOther",
                              placeholder: _ctx.t('Please specify'),
                              label: _ctx.t('Other'),
                              "label-align": _ctx.left
                            }, null, 8, ["value", "placeholder", "label", "label-align"])
                          ]),
                          _createVNode(_component_a_button, {
                            class: "performer-work-with-me__form__form-submit",
                            type: "primary",
                            "html-type": "submit",
                            size: "large",
                            block: "",
                            loading: _ctx.submitPublicCreatorRequestLoading,
                            disabled: _ctx.submitPublicCreatorRequestLoading
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("Submit")), 1)
                            ]),
                            _: 1
                          }, 8, ["loading", "disabled"])
                        ]),
                        _: 1
                      }, 8, ["model", "onFinish"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createVNode(_component_AppIcon, {
                        class: "performer-work-with-me__form-success__icon",
                        name: "phoneCircle"
                      }),
                      _createVNode(_component_a_typography_title, {
                        class: "mb-6",
                        level: 2
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Thank you!")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_typography_paragraph, { class: "performer-work-with-me__form-success__message" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t(
                  "We will reach out to you via email. If you really want to get started, you can book a call now."
                )), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _ctx.handleBookACall
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Book a call now")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]))
              ], 2)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
}