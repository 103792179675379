
import { defineComponent, reactive, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { usePerformerDetails } from "@/shared/composables/usePerformerDetails";
import { useLeads } from "@/shared/composables/useLeads";
import {
  makeRequiredRule,
  makeEmailRule,
} from "@/shared/utils/validators/commonValidators";
import { openInNewTab, scrollToElement } from "@/shared/utils/browser";
import PerformerCard from "@/shared/components/Performers/PerformerCard.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import InputPhoneNumber from "@/shared/components/Forms/InputPhoneNumber.vue";
import InputTextArea from "@/shared/components/Forms/InputTextArea.vue";
import InputNumber from "@/shared/components/Forms/InputNumber.vue";
import InputCheckbox from "@/shared/components/Forms/InputCheckbox.vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import LayoutIcon from "@/shared/components/Icons/LayoutIcon.vue";
import { join, isEmpty, delay } from "lodash";
import { useWindowSizeChecker } from "@/shared/utils/windowSizeChecker";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const id = String(route.params.id);
    const referrer = document.referrer ?? "";

    const { performerDetails, performerDetailsLoading } = usePerformerDetails({
      input: { id },
    });

    const { submitPublicCreatorRequest, submitPublicCreatorRequestLoading } =
      useLeads();

    const formState = reactive({
      businessName: "",
      email: "",
      phoneNumber: "",
      instagramUrl: "",
      description: "",
      videoCount: 1,
      discovery: [] as string[],
      discoveryOther: "",
    });

    const formSuccess = ref(false);

    const discoveryMerged = computed(() => {
      const discoveryOther = !isEmpty(formState.discoveryOther)
        ? ", " + formState.discoveryOther
        : "";
      return join(formState.discovery, ", ") + discoveryOther;
    });

    const { isMobile } = useWindowSizeChecker();

    const handleValidForm = async () => {
      const response = await submitPublicCreatorRequest({
        performerId: id,
        businessName: formState.businessName,
        phoneNumber: formState.phoneNumber,
        email: formState.email,
        instagramUrl: formState.instagramUrl,
        description: formState.description,
        referrer,
        // TODO: Add functionality on next ticket, added for now to fix typing error
        videoCount: formState.videoCount,
        discovery: discoveryMerged.value,
      });

      if (response?.success) {
        formSuccess.value = true;
        if (isMobile) {
          /**  */
          delay(() => {
            scrollToElement("performer-work-with-me__form-success");
          }, 1);
        }
      }
    };

    const handleBookACall = () => {
      const bookACallLink = "https://calendly.com/mimi-kamilas4am/30min";
      openInNewTab(bookACallLink);
    };

    const discoveryOptions = [
      {
        label: t("I saw the Founder's post"),
        value: t("I saw the Founder's post"),
      },
      { label: t("From ads"), value: t("From ads") },
      {
        label: t("From the creator’s social media"),
        value: t("From the creator’s social media"),
      },
    ];

    return {
      t,
      performerDetails,
      performerDetailsLoading,
      formState,
      formSuccess,
      submitPublicCreatorRequestLoading,
      discoveryOptions,
      makeRequiredRule,
      makeEmailRule,
      handleValidForm,
      handleBookACall,
    };
  },
  components: {
    PerformerCard,
    FormGroup,
    InputText,
    InputPhoneNumber,
    InputTextArea,
    InputNumber,
    InputCheckbox,
    AppIcon,
    LayoutIcon,
  },
});
